* {
	--light_pink: #FFD4E1;
	--medium_pink: #EC59B0;
	--dark_pink: #C94C96;
	--grey: #F0F0F0;
	--white: #FFF;
	--black: #000;
}
a {
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
p {
	margin: 0;
}
a,
.btn:focus,
input, 
button {
	outline: none;
}
body {
	font-family: 'Roboto', sans-serif;
	color: rgba(0, 0, 0, 0.6);
}
.overflow-hidden {
	overflow: hidden;
}
.position-absolute {
	position: absolute;
}
.btn {
	color: var(--white);
	background: var(--medium_pink);
	border: 1px solid var(--medium_pink);
	font-size: 20px;
	text-align: center;
	padding: 11px 24px;
	display: inline-block;
	text-decoration: none;
	border-radius: 8px;
}
.btn:hover {
	background: var(--white);
	color: var(--black);
	text-decoration: none;
}
.h1_title {
	font-size: 48px;
}
nav.navbar {
	background: var(--medium_pink);
}








footer {
	background-color: #f5f5f5;
}